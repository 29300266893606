import Cookies from 'js-cookie';
// import * as Sentry from '@sentry/nextjs';

import {
  emailLogin,
  emailSignUp,
  getUserDataWithUserProfile,
  socialLogin,
  socialSignUp,
  updateUserData,
  updateUserProfile,
} from '../client/OneistoxApiClient';
import { popupSlice } from '../reducers/popup';
import { authSlice } from '../reducers/auth';
import { trackauth, tracklogin, tracksignup } from '../analytics/analytics';

// logout a user
export const logout = () => (dispatch) => {
  // Sentry.setUser({ userId: null });
  dispatch(
    authSlice.actions.logout({
      redirect: true,
    }),
  );
};

// signup a user
export const signupStart = () => (dispatch) => {
  dispatch(authSlice.actions.signUpStart(null));
};

// login failed
export const signupFail = (error) => async (dispatch) => {
  // Sentry.setUser({ userId: null });
  dispatch(authSlice.actions.signUpFail(null));
  dispatch(popupSlice.actions.errorPopup({ error: error }));
};

// signup
export const signup = (data) => async (dispatch) => {
  try {
    const res = await emailSignUp(data.email, data.password);

    dispatch(authSlice.actions.signUpSuccess(res.data));

    const resUDUP = (await getUserDataWithUserProfile()).data;
    const userDataRes = resUDUP.userData;
    const userProfileRes = resUDUP.userProfile;

    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      }),
    );

    dispatch(popupSlice.actions.closePopup(null));

    dispatch(popupSlice.actions.openPopup({ name: 'WelcomePopup' }));

    tracksignup(
      userDataRes.userId,
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
      },
      'User Signup',
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
        company: '',
        company_address: '',
        education_status: '',
        method: { name: 'SITE' },
        type: 'signup',
      },
    );
    // Sentry.setUser({ userId: userDataRes.userId });
  } catch (err) {
    const error = err.response?.data;
    // Sentry.setUser({ userId: null });
    dispatch(authSlice.actions.signUpFail(null));

    dispatch(popupSlice.actions.errorPopup({ error: error }));
  }
};

// social login a user
export const socialSignUpAction = (data, type) => async (dispatch) => {
  try {
    const res = await socialSignUp(type, data.credential);

    dispatch(authSlice.actions.loginSuccess(res.data));

    const resUDUP = (await getUserDataWithUserProfile()).data;
    const userDataRes = resUDUP.userData;
    const userProfileRes = resUDUP.userProfile;

    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      }),
    );

    dispatch(popupSlice.actions.closePopup(null));

    if (!userDataRes.phone || !userProfileRes.firstName) {
      dispatch(popupSlice.actions.openPopup({ name: 'WelcomePopup' }));
    }

    tracksignup(
      userDataRes.userId,
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
      },
      'User Signup',
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
        company: '',
        company_address: '',
        education_status: '',
        method: { name: 'GOOGLE' },
        type: 'signup',
      },
    );
    // Sentry.setUser({ userId: userDataRes.userId });
  } catch (err) {
    const error = err.response?.data;
    // Sentry.setUser({ userId: null });
    dispatch(authSlice.actions.signUpFail(null));

    dispatch(popupSlice.actions.errorPopup({ error: error }));
  }
};

// social login a user
export const social = (data, type) => async (dispatch) => {
  try {
    const res = await socialLogin(type, data.credential);

    dispatch(authSlice.actions.loginSuccess(res.data));

    const resUDUP = (await getUserDataWithUserProfile()).data;
    const userDataRes = resUDUP.userData;
    const userProfileRes = resUDUP.userProfile;

    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      }),
    );

    if (!userDataRes.phone || !userProfileRes.firstName) {
      dispatch(popupSlice.actions.openPopup({ name: 'WelcomePopup' }));
    }

    dispatch(popupSlice.actions.closePopup(null));

    tracklogin(
      userDataRes.userId,
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
      },
      'User Login',
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
        company: '',
        company_address: '',
        education_status: '',
        method: { name: 'GOOGLE' },
        type: 'login',
      },
    );
    // Sentry.setUser({ userId: userDataRes.userId });
  } catch (err) {
    const error = err.response?.data;
    // Sentry.setUser({ userId: null });
    dispatch(authSlice.actions.logout(null));

    dispatch(popupSlice.actions.errorPopup({ error: error }));
  }
};

// login a user start
export const loginStart = () => (dispatch) => {
  dispatch(authSlice.actions.loginStart(null));
};

// login failed
export const loginFail = (error) => async (dispatch) => {
  // Sentry.setUser({ userId: null });
  dispatch(authSlice.actions.logout(null));
  dispatch(popupSlice.actions.errorPopup({ error: error }));
};

// login a user
export const login = (data) => async (dispatch) => {
  try {
    const res = await emailLogin(data.email, data.password);

    dispatch(authSlice.actions.loginSuccess(res.data));

    const resUDUP = (await getUserDataWithUserProfile()).data;
    const userDataRes = resUDUP.userData;
    const userProfileRes = resUDUP.userProfile;

    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      }),
    );

    dispatch(popupSlice.actions.closePopup(null));

    if (!userDataRes.phone || !userProfileRes.firstName) {
      dispatch(popupSlice.actions.openPopup({ name: 'WelcomePopup' }));
    }

    tracklogin(
      userDataRes.userId,
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
      },
      'User Login',
      {
        name: userProfileRes.firstName,
        email: userDataRes.emailId,
        mobile: userDataRes.phone,
        company: '',
        company_address: '',
        education_status: '',
        method: { name: 'SITE' },
        type: 'login',
      },
    );
    // Sentry.setUser({ userId: userDataRes.userId });
  } catch (err) {
    const error = err.response?.data;
    // Sentry.setUser({ userId: null });
    dispatch(authSlice.actions.logout(null));

    dispatch(popupSlice.actions.errorPopup({ error: error }));
  }
};

// auth initialization
export const authCheckStatus = () => async (dispatch) => {
  const token = Cookies.get('token');
  if (!token) {
    dispatch(authSlice.actions.logout({ redirect: true }));
    dispatch(popupSlice.actions.openPopup({ name: 'SignupPopup', data: '' }));
  } else {
    try {
      const resUDUP = (await getUserDataWithUserProfile()).data;
      const userDataRes = resUDUP.userData;
      const userProfileRes = resUDUP.userProfile;

      dispatch(
        authSlice.actions.authenticate({
          userData: userDataRes,
          userProfile: userProfileRes,
        }),
      );

      // if (!profileRes.data.firstName) {
      //   dispatch({
      //     type: OPEN_POPUP,
      //     payload: { name: 'WelcomePopup' },
      //   });
      // }

      trackauth(
        userDataRes.userId,
        {
          name: userProfileRes.firstName,
          email: userDataRes.emailId,
          mobile: userDataRes.phone,
        },
        'Auth',
        {
          name: userProfileRes.firstName,
          email: userDataRes.emailId,
          mobile: userDataRes.phone,
          company: '',
          company_address: '',
          education_status: '',
          method: { name: 'SITE' },
          type: 'revalidated',
          integrations: {
            'Google Analytics': false,
          },
        },
      );
      // Sentry.setUser({ userId: userDataRes.userId });
    } catch (err) {
      console.error(err);
      // Sentry.setUser({ userId: null });
      dispatch(popupSlice.actions.openPopup({ name: 'SignupPopup', data: '' }));

      dispatch(authSlice.actions.logout({ redirect: true }));
    }
  }
};

// signup steps
export const signupSteps =
  (firstName, lastName, mobile) => async (dispatch) => {
    const name = {
      firstName: firstName,
      lastName: lastName,
    };

    try {
      const resUpdateName = await updateUserProfile(name);
      const resUpdateMobile = await updateUserData(mobile);
      const resUDUP = (await getUserDataWithUserProfile()).data;
      const userDataRes = resUDUP.userData;
      const userProfileRes = resUDUP.userProfile;

      dispatch(
        authSlice.actions.authenticate({
          userData: resUpdateMobile.data,
          userProfile: resUpdateName.data,
        }),
      );

      dispatch(
        authSlice.actions.stepSave({
          ...userDataRes,
          ...userProfileRes,
          name,
          mobile,
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };
